import { useState, useEffect, } from "react";
import Spinner from '../common/Spinner';
import API from '../API';
import Alert from '../common/Alert';
import LinkForm from './LinkForm';

export default function Link(props) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [categories, setCategories] = useState([]);
  const [mediaUrl, setMediaUrl] = useState('');

  const [data, setData] = useState({
    category_id: '',
    description: '',
    background_color: '#ffffff',
    order: 1,
    icon_file_name: null,
  });

  const [updatedData, setUpdatedData] = useState({});

  useEffect(() => {
    setLoading(true);

    async function fetchData() {
      try {
        setCategories(await API.getCategories());

        if (props.getLinkHandler) {
          setData(await props.getLinkHandler());

        }
      } catch(err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [props]);

  useEffect(() => {
    setMediaUrl(data.icon_file_name ? API.getMediaURL(data.icon_file_name) : '');
  }, [data]);

  useEffect(() => {
    setData(data => ({
      ...data,
      ...updatedData,
    }));
  }, [updatedData]);

  function onValueUpdate(newData) {
    setUpdatedData({
      ...updatedData,
      ...newData
    });
  }

  async function onSubmit(e) {
    e.preventDefault();

    setError('');
    setSubmitting(true);

    try {
      const payload = props.mode === 'add' ? { ...data, ...updatedData } : updatedData;
      await props.submitHandler(payload);
    } catch(err) {
      setError(err);
      setSubmitting(false);
    }
  }

  // callback for an ImageUploader
  async function uploadIcon(file) {
    const formData = new FormData();
    formData.append('icon', file)

    const response = await API.uploadHomepageLinkIcon(formData);

    onValueUpdate({
      icon_file_name: response.file_name,
    });
  }

  return (
    <div className="row">
      <div className="col-12">
        <h1>{props.title}</h1>

        {error && <Alert message={error} />}

        {loading && <Spinner align="left" />}

        {!loading &&
          <LinkForm submitting={submitting} categories={categories}
            data={data} setData={onValueUpdate}
            uploadHandler={uploadIcon} mediaUrl={mediaUrl}
            onSubmit={onSubmit} submitLabel={props.submitLabel}
          />
        }
      </div>
    </div>
  );
}
