import { useHistory } from 'react-router-dom';
import API from '../API';
import Admin from './Admin';

export default function AdminAdd() {
  const history = useHistory();

  async function submitHandler(data) {
    await API.createAdmin(data);
    history.push('/admins');
  }

  return (
    <Admin
      mode="add" title="Add Admin" submitLabel="Add"
      submitHandler={submitHandler}
    />
  );
}
