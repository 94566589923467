import { useHistory } from 'react-router-dom';

export default function GoodTr(props) {
  const history = useHistory();

  const trClassName = props.to ? 'link' : '';

  function onClick(e) {
    if (!props.to) {
      return true;
    }

    if (['A', 'BUTTON', ].includes(e.target.nodeName)) {
      return false;
    }

    e.preventDefault();
    e.stopPropagation();

    history.push(props.to);
  }

  return (
    <tr className={trClassName} onClickCapture={onClick}>
      {props.children}
    </tr>
  );
}