import { useContext } from 'react';
import Spinner from '../common/Spinner';
import MediaUploader from '../common/MediaUploader';
import { AuthContext } from "../AuthContext";

const mapPreviewStyle = {
  maxHeight: '400px',
  maxWidth: '400px',
};

export default function ListingForm(props) {
  const authContext = useContext(AuthContext);
  function handleInputChange(e) {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    props.setData({
      [name]: value,
    });

    props.data[name] = value;
  }

  return (
    <form className="" onSubmit={props.onSubmit}>
      <fieldset disabled={props.submitting}>

        {authContext.superAdmin ?

          <div className="mb-3 row">
            <label htmlFor="listingCategory" className="col-sm-2 col-form-label">Category</label>
            <div className="col-sm-10">
              <select name="category_id" id="listingCategory" className="form-select" required
                onChange={handleInputChange} value={props.data.category_id || ''}
              >
                <option value="">Select</option>
                {props.categories.map(category => <option key={category.id} value={category.id}>{category.title}</option>)}
              </select>
            </div>
          </div>

          : 

          <div className="mb-3 row">
            <label htmlFor="listingTitle" className="col-sm-2 col-form-label">Category</label>
            <div className="col-sm-10">
              {props.categories.find(v => v.id === props.data.category_id ).title}
            </div>
          </div>
        }


        <div className="mb-3 row">
          <label htmlFor="listingTitle" className="col-sm-2 col-form-label">Title</label>
          <div className="col-sm-10">
            <input type="text" className="form-control" id="listingTitle" name="title" maxLength="64" required
              onChange={handleInputChange} value={props.data.title || ''}
            />
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="listingDescription" className="col-sm-2 col-form-label">Description</label>
          <div className="col-sm-10">
            <textarea className="form-control" id="listingDescription" rows="10" name="description" required
              onChange={handleInputChange} value={props.data.description || ''}></textarea>
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="listingAddress" className="col-sm-2 col-form-label">Address</label>
          <div className="col-sm-10">
            <input type="text" className="form-control" id="listingAddress" name="address" maxLength="64" required
              onChange={handleInputChange} value={props.data.address || ''}
            />
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="listingPhone" className="col-sm-2 col-form-label">Phone</label>
          <div className="col-sm-10">
            <input type="text" className="form-control" id="listingPhone" name="phone" maxLength="64" required
              onChange={handleInputChange} value={props.data.phone || ''}
            />
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="listingCapacity" className="col-sm-2 col-form-label">Capacity</label>
          <div className="col-sm-10">
            <input type="text" className="form-control" id="listingCapacity" name="capacity" maxLength="64"
              onChange={handleInputChange} value={props.data.capacity || ''}
            />
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="listingImage" className="col-sm-2 col-form-label">Image</label>
          <div className="col-sm-10">
            <MediaUploader uploader={props.uploadHandler} mediaName={props.data.image_file_name} mediaUrl={props.imageUrl} />
            {props.data.image_file_name &&
              <figcaption className="figure-caption">
                <a href={props.image_file_name} target="_blank" rel="noreferrer">{props.data.image_file_name}</a>
              </figcaption>
            }
          </div>
        </div>

        {props.mode === 'edit' && 
          <div className="mb-3 row">
            <label htmlFor="listingMapImage" className="col-sm-2 col-form-label">Map Image</label>
            <div className="col-sm-10">
              {
                props.mapUrl && 
                <figure className="figure">
                  <img src={props.mapUrl} className="figure-img img-fluid rounded" style={mapPreviewStyle} alt="" />
                  <figcaption className="figure-caption">{props.data.map_file_name}</figcaption>
                </figure>
              }
            </div>
          </div>
        }

      </fieldset>

      <div className="mb-3 row">
        <div className="col-sm-12 col-12">
          {!props.submitting && <button type="submit" className="btn btn-primary">{props.submitLabel || 'Save'}</button>}
          {props.submitting && <Spinner align="center" />}
        </div>
      </div>
    </form>
  );
}