import { useState, useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import Spinner from '../common/Spinner';
import API from '../API';
import Alert from '../common/Alert';
import GoodTr from '../common/GoodTr';
import { AuthContext } from '../AuthContext';
import ActionButton from '../common/ActionButton';

export default function AdminsList() {
  const authContext = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [admins, setAdmins] = useState([]);

  useEffect(() => {
    setLoading(true);

    async function fetchData() {
      try {
        setAdmins(await API.getAdmins());
      } catch(err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  async function deleteAdmin(id) {
    if (!window.confirm(`Are you sure?`)) {
      return false;
    }

    try {
      await API.deleteAdmin(id);
      setAdmins(admins.filter(v => v.id !== id));
    } catch(error) {
      setError(error.toString());
    }

    return true;
  }

  return (
    <div className="row">
      <div className="col-12">
        <h1>
          Admins
          { authContext.canAdd('admins') && <NavLink to="/admins/add" className="btn btn-success btn-sm ms-3">Add</NavLink> }
        </h1>
            
        {error && <Alert message={error} onHide={() => setError(false)} />}

        {loading && <Spinner align="left" />}

        {!loading &&
          <>
            <table className="table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Login</th>
                  <th>Permissions</th>
                  <th>Created At</th>
                  <th>Updated At</th>
                  { authContext.canDelete('admins') && <th></th> }
                </tr>
              </thead>
              <tbody>
                {admins.map(admin => {
                  const createdAt = new Date(admin.created_at);

                  return (
                    <GoodTr key={admin.id} to={`/admins/${admin.id}`}>
                      <td>
                        <NavLink to={`/admins/${admin.id}`}>{admin.id}</NavLink>
                      </td>
                      <td>
                        {admin.login}
                      </td>
                      <td>
                        {!!admin.super_admin && <span className="badge bg-light text-dark border border-dark me-1">Super admin</span>}
                        {
                          Object.keys(admin.permissions).map((entity, i) => 
                            <div key={i} className="border-bottom">
                              {entity}:&nbsp;
                              {
                                admin.permissions[entity][0] === null
                                ? 'Full Access'
                                : admin.permissions[entity]
                                  .map(entityId => <a key={entityId} href={`/${entity}/${entityId}`}>{entityId}</a>)
                                  .reduce((prev, curr) => [prev, ', ', curr])
                              }
                            </div>
                          )
                        }
                      </td>
                      <td>{createdAt.toLocaleDateString()} {createdAt.toLocaleTimeString()}</td>
                      { authContext.canDelete('admins') && 
                        <td>
                          <ActionButton onClick={() => deleteAdmin(admin.id)} label="Delete" className="btn-danger btn-sm" />
                        </td>
                      }
                    </GoodTr>
                  )
                })}
              </tbody>
            </table>
          </>
        }
      </div>
    </div>
  );
}
