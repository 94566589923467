import { useHistory } from 'react-router-dom';
import API from '../API';
import Link from './Link';

export default function HomepageLinkAdd() {
  const history = useHistory();

  async function submitHandler(data) {
    await API.createHomepageLink(data);
    history.push('/homepage/links');
  }

  return (
    <Link
      mode="add" title="Add Homepage Link" submitLabel="Add"
      submitHandler={submitHandler}
    />
  );
}
