import { useContext } from "react";
import { AuthContext } from "./AuthContext";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import Topbar from './Topbar';

import HomepageAdsList from './HomepageAds/List.js';
import HomepageAdAdd from './HomepageAds/Add.js';
import HomepageAdEdit from './HomepageAds/Edit.js';

import HomepageLinksList from './HomepageLinks/List.js';
import HomepageLinkAdd from './HomepageLinks/Add.js';
import HomepageLinkEdit from './HomepageLinks/Edit.js';

import CategoriesList from './Categories/List.js';
import CategoryAdd from './Categories/Add.js';
import CategoryEdit from './Categories/Edit.js';

import ListingsList from './Listings/List.js';
import ListingAdd from './Listings/Add.js';
import ListingEdit from './Listings/Edit.js';

import KiosksList from './Kiosks/List.js';

import AdminsList from './Admins/List.js';
import AdminAdd from './Admins/Add.js';
import AdminEdit from './Admins/Edit.js';

export default function Dashboard() {
  const authContext = useContext(AuthContext);

  const links = [
    {
      path: '/homepage/ads',
      permission: 'homepage_ads',
      title: 'Homepage Ads',
      routes: [
        {
          path: '/homepage/ads',
          component: HomepageAdsList,
        },
        {
          path: '/homepage/ads/add',
          component: HomepageAdAdd,
        },
        {
          path: '/homepage/ads/:adId',
          component: HomepageAdEdit,
        },
      ],
    },
    {
      path: '/homepage/links',
      permission: 'homepage_links',
      title: 'Homepage Links',
      routes: [
        {
          path: '/homepage/links',
          component: HomepageLinksList,
        },
        {
          path: '/homepage/links/add',
          component: HomepageLinkAdd,
        },
        {
          path: '/homepage/links/:linkId',
          component: HomepageLinkEdit,
        },
      ],
    },
    {
      path: '/categories',
      permission: 'categories',
      title: 'Categories',
      routes: [
        {
          path: '/categories',
          component: CategoriesList,
        },
        {
          path: '/categories/add',
          component: CategoryAdd,
        },
        {
          path: '/categories/:categoryId',
          component: CategoryEdit,
        },
        {
          path: '/categories/:categoryId/listings',
          component: ListingsList,
        },
        {
          path: '/categories/:categoryId/listings/:listingId',
          component: ListingEdit,
        },
      ],
    },
    {
      path: '/listings',
      permission: 'listings',
      title: 'Listings',
      routes: [
        {
          path: '/listings',
          component: ListingsList,
        },
        {
          path: '/listings/add',
          component: ListingAdd,
        },
        {
          path: '/listings/:listingId',
          component: ListingEdit,
        },
      ],
    },
    {
      path: '/kiosks',
      permission: 'kiosks',
      title: 'Kiosks',
      routes: [
        {
          path: '/kiosks',
          component: KiosksList,
        },
      ],
    },

    {
      path: '/admins',
      permission: 'admins',
      title: 'Admins',
      routes: [
        {
          path: '/admins',
          component: AdminsList,
        },
        {
          path: '/admins/add',
          component: AdminAdd,
        },
        {
          path: '/admins/:adminId',
          component: AdminEdit,
        },
      ],
    },
  ];

  const allowedLinks = links.filter(({permission}) => authContext.canAccess(permission));

  return authContext.isLoggedIn && (
    <Router>
      <Topbar allowedLinks={allowedLinks} />

      <div className="container mt-3">
        <Switch>
          {allowedLinks.map(v => v.routes).flat().map(({path, component}, i) => 
            <Route key={i} path={path} component={component} exact />
          )}
          {allowedLinks.length && <Route path="*" exact={true} render={() => <Redirect to={allowedLinks[0].path} push />} />}
        </Switch>
      </div>
    </Router>
  );
}
