import { useState } from "react";
import Spinner from '../common/Spinner';

export default function ActionButton(props) {
  const [loading, setLoading] = useState(false);

  function onClick(e) {
    setLoading(true);
    if (props.onClick(e) === false) {
      setLoading(false);
    }
  }

  if (loading) {
    return <Spinner align="center" />;
  }

  return (
    <button type="button" className={`btn ${props.className}`} onClick={onClick}>{props.label}</button>
  );
}