import { useContext, useState } from "react";
import { AuthContext } from "./AuthContext";
import Spinner from './common/Spinner';
import API from './API';
import Alert from './common/Alert';

export default function Auth() {
  const authContext = useContext(AuthContext);
  const [submitting, setSubmitting] = useState(false);
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);

  const loginHandler = (e) => {
    e.preventDefault();
    
    setSubmitting(true);
    
    API.login(login, password)
      .then((data) => {
        setSubmitting(false);
        authContext.login(data);
      })
      .catch(err => {
        setError(err);
        setSubmitting(false);
      });
  };

  if (authContext.isLoggedIn) {
    return null;
  }

  return (
    <>
      <div className="d-flex align-items-center min-vh-100 row login-page">
        <div className="col-sm-12 offset-sm-0 col-md-6 offset-md-3 col-lg-4 offset-lg-4">
          
          {error && <Alert message={error} />}

          <form className="p-4 rounded" onSubmit={loginHandler}>
            <div className="mb-3">
              <label htmlFor="login" className="form-label">Login</label>
              <input type="text" className="form-control" id="login" onChange={e => setLogin(e.target.value)} required />
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">Password</label>
              <input type="password" className="form-control" id="password" onChange={e => setPassword(e.target.value)} required />
            </div>
            {!submitting && <button type="submit" className="btn btn-primary">Log Me In!</button>}
            {submitting && <Spinner align="center" />}
          </form>
        </div>
      </div>
    </>
  );
}
