const API_URL = '/admin-api';

function qs(data) {
  return Object.keys(data).map(function(key) {
    return [key, data[key]].map(encodeURIComponent).join("=");
  }).join("&");
}   

let token = null;

async function request(endpoint, method, body = {}, headers = {}) {
  let url = API_URL + endpoint;

  const isFileUpload = body instanceof FormData;

  headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    ...headers,
  };

  if (isFileUpload) {
    delete headers['Content-Type'];
  }
  
  let options = {
    headers,
    cache: 'no-cache',
    method,
  }

  if (method === 'GET' || method === 'HEAD') {
    if (Object.keys(body).length) {
      url += '?' + qs(body);
    }
  } else if (!isFileUpload) {
    options.body = JSON.stringify(body);
  } else {
    options.body = body;
  }

  console.debug('Sending to API', url, {body, headers});

  return fetch(url, options)
    .then(async response => {
      try {
        const result = await response.json();
        return result;
      } catch(e) {
        console.error('Invalid JSON', e);
        throw response.statusText;
      }
    })
    .then(result => {
      if (result.error) {
        throw result.error;
      }

      return result;
    });
}

function authRequest(endpoint, method, body = {}, headers = {}) {
  return request(endpoint, method, body, { authorization: `Bearer ${token}`, ...headers })
}

const API = {
  _useToken: t => token = t,

  login: (login, password) => request('/auth', 'POST', {login, password}),
  getSessions: () => authRequest('/auth/sessions', 'GET', {}),
  revokeToken: tokenId => authRequest('/auth/revoke-token', 'POST', { tokenId }),

  createCategory: payload => authRequest(`/categories`, 'POST', payload),
  getCategories: () => authRequest('/categories', 'GET'),
  getCategory: categoryId => authRequest(`/categories/${categoryId}`, 'GET'),
  getCategoryListings: categoryId => authRequest(`/categories/${categoryId}/listings`, 'GET'),
  updateCategory: (categoryId, payload) => authRequest(`/categories/${categoryId}`, 'PATCH', payload),
  uploadCategoryMedia: body => authRequest(`/categories/upload`, 'POST', body, { 'Content-Type': 'multipart/form-data' }),
  deleteCategory: categoryId => authRequest(`/categories/${categoryId}`, 'DELETE'),

  createListing: payload => authRequest(`/listings`, 'POST', payload),
  getListings: () => authRequest(`/listings`, 'GET'),
  getListing: listingId => authRequest(`/listings/${listingId}`, 'GET'),
  updateListing: (listingId, payload) => authRequest(`/listings/${listingId}`, 'PATCH', payload),
  uploadListingMedia: body => authRequest(`/listings/upload`, 'POST', body, { 'Content-Type': 'multipart/form-data' }),
  deleteListing: listingId => authRequest(`/listings/${listingId}`, 'DELETE'),

  getKiosks: () => authRequest('/kiosks', 'GET'),
  getKiosk: id => authRequest(`/kiosks/${id}`, 'GET'),

  getHomepageLinks: () => authRequest('/homepage/links', 'GET'),
  getHomepageLink: linkId => authRequest(`/homepage/links/${linkId}`, 'GET'),
  createHomepageLink: payload => authRequest(`/homepage/links/`, 'POST', payload),
  updateHomepageLink: (linkId, payload) => authRequest(`/homepage/links/${linkId}`, 'PATCH', payload),
  uploadHomepageLinkIcon: body => authRequest(`/homepage/links/upload-icon`, 'POST', body, { 'Content-Type': 'multipart/form-data' }),
  deleteHomepageLink: (linkId) => authRequest(`/homepage/links/${linkId}`, 'DELETE'),

  getHomepageAds: () => authRequest('/homepage/ads', 'GET'),
  getHomepageAd: adId => authRequest(`/homepage/ads/${adId}`, 'GET'),
  createHomepageAd: payload => authRequest(`/homepage/ads/`, 'POST', payload),
  updateHomepageAd: (adId, payload) => authRequest(`/homepage/ads/${adId}`, 'PATCH', payload),
  uploadHomepageAdMedia: body => authRequest(`/homepage/ads/upload`, 'POST', body, { 'Content-Type': 'multipart/form-data' }),
  deleteHomepageAd: linkId => authRequest(`/homepage/ads/${linkId}`, 'DELETE'),

  createAdmin: payload => authRequest(`/admins`, 'POST', payload),
  getAdmins: () => authRequest('/admins', 'GET'),
  getAdmin: adminId => authRequest(`/admins/${adminId}`, 'GET'),
  updateAdmin: (adminId, payload) => authRequest(`/admins/${adminId}`, 'PATCH', payload),
  deleteAdmin: adminId => authRequest(`/admins/${adminId}`, 'DELETE'),

  getMediaURL: mediaName => `${API_URL}/media/${mediaName}`, 
}

export default API;
