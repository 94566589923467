import { useState, useEffect, useContext } from "react";
import { NavLink, useParams } from "react-router-dom";
import Spinner from '../common/Spinner';
import API from '../API';
import Alert from '../common/Alert';
import GoodTr from '../common/GoodTr';
import { AuthContext } from '../AuthContext';
import ActionButton from '../common/ActionButton';

export default function ListingsList(props) {
  const authContext = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [category, setCategory] = useState(false);
  const [listings, setListings] = useState([]);
  const { categoryId } = useParams();

  useEffect(() => {
    setLoading(true);

    async function fetchData() {
      try {
        if (categoryId) {
          setCategory(await API.getCategory(categoryId));
          setListings(await API.getCategoryListings(categoryId));
        } else {
          setListings(await API.getListings(categoryId));
        }
      } catch(err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [categoryId]);

  async function deleteListing(id) {
    if (!window.confirm(`Are you sure you want to delete "${listings.find(v => v.id === id).title}"?`)) {
      return false;
    }

    try {
      await API.deleteListing(id);
      setListings(listings.filter(v => v.id !== id));
    } catch(error) {
      setError(error.toString());
    }

    return true;
  }

  return (
    <div className="row">
      <div className="col-12">
        <h1>
          {category ? category.title : 'Listings'}
          { authContext.canAdd('listings') && <NavLink to={`/listings/add${category ? `?category_id=${category.id}` : ''}`} className="btn btn-success btn-sm ms-3">Add</NavLink> }
        </h1>
            
        {error && <Alert message={error} />}

        {loading && <Spinner align="left" />}

        {!loading &&
          <>
            <table className="table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Title</th>
                  <th>Address</th>
                  <th>Phone</th>
                  <th>Created At</th>
                  <th>Updated At</th>
                  { authContext.canDelete('listings') && <th></th> }
                </tr>
              </thead>
              <tbody>
                {listings.map(listing => {
                  const createdAt = new Date(listing.created_at);
                  const updatedAt = listing.updated_at !== null ? new Date(listing.updated_at) : null;

                  return (
                    <GoodTr key={listing.id} to={`/listings/${listing.id}`}>
                      <td>
                        <NavLink to={`/listings/${listing.id}`}>{listing.id}</NavLink>
                      </td>
                      <td>{listing.title}</td>
                      <td>{listing.address}</td>
                      <td>{listing.phone}</td>
                      <td>{createdAt.toLocaleDateString()} {createdAt.toLocaleTimeString()}</td>
                      <td>{updatedAt && updatedAt.toLocaleDateString()} {updatedAt && updatedAt.toLocaleTimeString()}</td>
                      { authContext.canDelete('listings') && 
                        <td>
                          <ActionButton onClick={() => deleteListing(listing.id)} label="Delete" className="btn-danger btn-sm" />
                        </td>
                      }
                    </GoodTr>
                  )
                })}
              </tbody>
            </table>
          </>
        }
      </div>
    </div>
  );
}
