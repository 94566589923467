import { useHistory } from 'react-router-dom';
import API from '../API';
import Category from './Category';

export default function CategoryAdd() {
  const history = useHistory();

  async function submitHandler(data) {
    await API.createCategory(data);
    history.push('/categories');
  }

  return (
    <Category
      mode="add" title="Add Category" submitLabel="Add"
      submitHandler={submitHandler}
    />
  );
}
