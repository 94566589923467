import Spinner from '../common/Spinner';
import MediaUploader from '../common/MediaUploader';

export default function LinkForm(props) {
  function handleInputChange(e) {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    props.setData({
      [name]: value,
    });
    
    props.data[name] = value;
  }

  return (
    <form className="" onSubmit={props.onSubmit}>
      <fieldset disabled={props.submitting}>
        <div className="mb-3 row">
          <label htmlFor="newCategory" className="col-sm-2 col-form-label">Category</label>
          <div className="col-sm-10">
            <select name="category_id" id="newCategory" className="form-select" required
              onChange={handleInputChange} value={props.data.category_id}
            >
              <option value="">Select</option>
              {props.categories.map(category => <option key={category.id} value={category.id}>{category.title}</option>)}
            </select>
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="newDescription" className="col-sm-2 col-form-label">Description</label>
          <div className="col-sm-10">
            <input type="text" className="form-control" id="newDescription" name="description" maxLength="64" required
              onChange={handleInputChange} value={props.data.description}
            />
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="newBackgroundColor" className="col-sm-2 col-form-label">Background Color</label>
          <div className="col-sm-10">
            <input type="color" className="form-control form-control-color" id="newBackgroundColor" name="background_color" required
              onChange={handleInputChange} value={props.data.background_color}
            />
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="newIcon" className="col-sm-2 col-form-label">Icon</label>
          <div className="col-sm-10">
            <MediaUploader uploader={props.uploadHandler} mediaName={props.data.icon_file_name} mediaUrl={props.mediaUrl} />
            {props.data.icon_file_name &&
              <figcaption className="figure-caption">
                <a href={props.icon_file_name} target="_blank" rel="noreferrer">{props.data.icon_file_name}</a>
              </figcaption>
            }
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="newOrder" className="col-sm-2 col-form-label">Order</label>
          <div className="col-sm-10">
            <input type="number" min="1" step="1" className="form-control" id="newOrder" name="order" required
              onChange={handleInputChange} value={props.data.order}
            />
          </div>
        </div>
      </fieldset>

      <div className="mb-3 row">
        <div className="col-sm-12 col-12">
          {!props.submitting && <button type="submit" className="btn btn-primary">{props.submitLabel || 'Save'}</button>}
          {props.submitting && <Spinner align="center" />}
        </div>
      </div>
    </form>
  );
}