import { useHistory, useParams } from 'react-router-dom';
import API from '../API';
import Link from './Link';

export default function HomepageLinkEdit() {
  const history = useHistory();
  const { linkId } = useParams();

  function getLink() {
    return API.getHomepageLink(linkId);
  }

  async function submitHandler(data) {
    await API.updateHomepageLink(linkId, data);
    history.push('/homepage/links');
  }

  return (
    <Link
      getLinkHandler={getLink}
      title="Edit Homepage Link" submitLabel="Save"
      submitHandler={submitHandler}
    />
  );
}
