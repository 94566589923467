import Spinner from '../common/Spinner';
import MediaUploader from '../common/MediaUploader';

export default function AdForm(props) {
  function handleInputChange(e) {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    props.setData({
      [name]: value,
    });
    
    props.data[name] = value;
  }

  return (
    <form className="" onSubmit={props.onSubmit}>
      <fieldset disabled={props.submitting}>
        <div className="mb-3 row">
          <label htmlFor="newKiosk" className="col-sm-2 col-form-label">Kiosk</label>
          <div className="col-sm-10">
            <select name="kiosk_id" id="newKiosk" className="form-select" required
              onChange={handleInputChange} value={props.data.kiosk_id}
            >
              <option value="">Select</option>
              {props.kiosks.map(kiosk => <option key={kiosk.id} value={kiosk.id}>{kiosk.title}</option>)}
            </select>
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="newCategory" className="col-sm-2 col-form-label">Category</label>
          <div className="col-sm-10">
            <select name="category_id" id="newCategory" className="form-select" required
              onChange={handleInputChange} value={props.data.category_id}
            >
              <option value="">Select</option>
              {props.categories.map(category => <option key={category.id} value={category.id}>{category.title}</option>)}
            </select>
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="newTitle" className="col-sm-2 col-form-label">Title</label>
          <div className="col-sm-10">
            <input type="text" className="form-control" id="newTitle" name="title" required
              onChange={handleInputChange} value={props.data.title}
            />
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="newMedia" className="col-sm-2 col-form-label">Media</label>
          <div className="col-sm-10">
            <MediaUploader uploader={props.uploadHandler} mediaName={props.data.ad_file_name} mediaUrl={props.mediaUrl} required={props.mediaRequired || false} />
            {props.data.ad_file_name &&
              <figcaption className="figure-caption">
                <a href={props.ad_file_name} target="_blank" rel="noreferrer">{props.data.ad_file_name}</a>
              </figcaption>
            }
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="newOrder" className="col-sm-2 col-form-label">Order</label>
          <div className="col-sm-10">
            <input type="number" min="1" step="1" className="form-control" id="newOrder" name="order" required
              onChange={handleInputChange} value={props.data.order}
            />
          </div>
        </div>
      </fieldset>

      <div className="mb-3 row">
        <div className="col-sm-12 col-12">
          {!props.submitting && <button type="submit" className="btn btn-primary">{props.submitLabel || 'Save'}</button>}
          {props.submitting && <Spinner align="center" />}
        </div>
      </div>
    </form>
  );
}