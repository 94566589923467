import React, { useState, useEffect, useCallback } from 'react';
import { AuthContext } from "./AuthContext";
import Authentication from "./Auth";
import Dashboard from "./Dashboard";
import API from "./API";

function App() {
  const [data, setData] = useState({});

  const storageKey = 'admin-auth-data';

  const login = useCallback((data) => {
    API._useToken(data.token);
    setData(data);

    localStorage.setItem(storageKey, JSON.stringify(data));
  }, []);

  const logout = useCallback(() => {
    setData({});
    localStorage.removeItem(storageKey);
  }, []);

  const canAccess = section => data.super_admin || (data.sections && data.sections.includes(section));
  const canAdd = section => !!data.super_admin;
  const canDelete = entity => !!data.super_admin;

  useEffect(() => {
    try {
      const data = JSON.parse(localStorage.getItem(storageKey));
      if (data) {
        login(data);
      }
    } catch(e) {
      console.error(e);
    }
  }, [login]);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!data.token,
        superAdmin: data.super_admin,
        sections: data.sections,
        login: login,
        logout: logout,
        canAccess: canAccess,
        canAdd: canAdd,
        canDelete: canDelete,
      }}
    >
      <Authentication />
      <Dashboard />
    </AuthContext.Provider>
  );
}

export default App;
