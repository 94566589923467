import { useHistory, useParams } from "react-router-dom";
import API from '../API';
import Listing from './Listing';

export default function ListingEdit() {
  const history = useHistory();
  const { listingId } = useParams();

  function getListing() {
    return API.getListing(listingId);
  }

  async function submitHandler(data) {
    await API.updateListing(listingId, data);
    history.push('/listings');
  }

  return (
    <Listing
      mode="edit" getListingHandler={getListing}
      title="Edit Listing" submitLabel="Save"
      mediaRequired={false}
      submitHandler={submitHandler}
    />
  );
}
