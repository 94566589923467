import Spinner from '../common/Spinner';

export default function AdminForm(props) {
  function handleInputChange(e) {
    const target = e.target;
    const name = target.name;
    const value = target.type === 'checkbox'
      ? target.checked
      : (target.type === 'select-multiple')
        ? Array.from(e.target.selectedOptions, option => option.value)
        : target.value;

    props.setData({
      [name]: value,
    });
    
    props.data[name] = value;
  }

  return (
    <form className="" onSubmit={props.onSubmit}>
      <fieldset disabled={props.submitting}>
        <div className="mb-3 row">
          <label htmlFor="adminLogin" className="col-sm-2 col-form-label">Login</label>
          <div className="col-sm-10">
            <input type="text" className="form-control" id="adminLogin" name="login" maxLength="64" required
              onChange={handleInputChange} value={props.data.login}
            />
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="adminNewPwd" className="col-sm-2 col-form-label">New Password</label>
          <div className="col-sm-10">
            <input type="password" className="form-control" id="adminNewPwd" name="password" minLength="8" maxLength="64"
              onChange={handleInputChange} value={props.data.password || ''} required={props.mode === 'add'}
            />
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="adminSuper" className="col-sm-2 col-form-label">Super Admin</label>
          <div className="col-sm-10">
            <input type="checkbox" className="form-check-input" id="adminSuper" name="super_admin" minLength="8" maxLength="64"
              onChange={handleInputChange} checked={props.data.super_admin}
            />
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="adminListings" className="col-sm-2 col-form-label">Listings</label>
          <div className="col-sm-10">
            <select className="form-select" id="adminListings" name="permissions.listings" size="15" multiple
            onChange={handleInputChange} value={props.data.permissions.listings || []}
            >
              {props.listings && props.listings.map(listing => 
                <option key={listing.id} value={listing.id}>{`${listing.title} (ID: ${listing.id})`}</option>
              )}
            </select>
          </div>
        </div>

      </fieldset>

      <div className="mb-3 row">
        <div className="col-sm-12 col-12">
          {!props.submitting && <button type="submit" className="btn btn-primary">{props.submitLabel || 'Save'}</button>}
          {props.submitting && <Spinner align="center" />}
        </div>
      </div>
    </form>
  );
}