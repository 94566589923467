import { useHistory, useParams } from 'react-router-dom';
import API from '../API';
import Admin from './Admin';

export default function AdminEdit() {
  const history = useHistory();
  const { adminId } = useParams();

  function getAdmin() {
    return API.getAdmin(adminId);
  }

  async function submitHandler(data) {
    await API.updateAdmin(adminId, data);
    history.push('/admins');
  }

  return (
    <Admin
      getAdminHandler={getAdmin}
      title="Edit Admin" submitLabel="Save"
      submitHandler={submitHandler}
    />
  );
}
