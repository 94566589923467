import { createContext } from "react";

export const AuthContext = createContext({
  isLoggedIn: false,
  superAdmin: false,
  sections: [],
  login: () => {},
  logout: () => {},
  canAccess: () => {},
  canAdd: () => {},
  canDelete: () => {},
});
