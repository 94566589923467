import { useHistory, useParams } from 'react-router-dom';
import API from '../API';
import Category from './Category';

export default function CategoryEdit() {
  const history = useHistory();
  const { categoryId } = useParams();

  function getCategory() {
    return API.getCategory(categoryId);
  }

  async function submitHandler(data) {
    await API.updateCategory(categoryId, data);
    history.push('/categories');
  }

  return (
    <Category
      getCategoryHandler={getCategory}
      title="Edit Category" submitLabel="Save"
      mediaRequired={false}
      submitHandler={submitHandler}
    />
  );
}
