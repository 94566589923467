import { useHistory, useParams } from 'react-router-dom';
import API from '../API';
import Ad from './Ad';

export default function HomepageAdEdit() {
  const history = useHistory();
  const { adId } = useParams();

  function getAd() {
    return API.getHomepageAd(adId);
  }

  async function submitHandler(data) {
    await API.updateHomepageAd(adId, data);
    history.push('/homepage/ads');
  }

  return (
    <Ad
      getAdHandler={getAd}
      title="Edit Homepage Ad" submitLabel="Save"
      mediaRequired={false}
      submitHandler={submitHandler}
    />
  );
}
