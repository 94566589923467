import { useState, useEffect, } from "react";
import Spinner from '../common/Spinner';
import API from '../API';
import Alert from '../common/Alert';

export default function KiosksList() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [kiosks, setKiosks] = useState([]);

  useEffect(() => {
    setLoading(true);

    async function fetchData() {
      try {
        setKiosks(await API.getKiosks());
      } catch(err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  return (
    <div className="row">
      <div className="col-12">
        <h1>Kiosks</h1>
            
        {error && <Alert message={error} />}

        {loading && <Spinner align="left" />}

        {!loading && !error &&
          <>
            <table className="table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Title</th>
                  <th>Created At</th>
                </tr>
              </thead>
              <tbody>
                {kiosks.map(kiosk => {
                  const createdAt = new Date(kiosk.created_at);

                  return (
                    <tr key={kiosk.id}>
                      <td>
                        {kiosk.id}
                      </td>
                      <td>{kiosk.title}</td>
                      <td>{createdAt.toLocaleDateString()} {createdAt.toLocaleTimeString()}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </>
        }
      </div>
    </div>
  );
}
