import { useState, useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import Spinner from '../common/Spinner';
import API from '../API';
import Alert from '../common/Alert';
import ActionButton from '../common/ActionButton';
import GoodTr from '../common/GoodTr';
import _ from 'lodash';
import { AuthContext } from '../AuthContext';

export default function HomepageLinkCreate() {
  const authContext = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [categories, setCategories] = useState([]);
  const [links, setLinks] = useState([]);

  useEffect(() => {
    setLoading(true);

    async function fetchData() {
      try {
        setCategories(await API.getCategories());
        setLinks(await API.getHomepageLinks());
      } catch(err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  async function deleteLink(linkId) {
    try {
      await API.deleteHomepageLink(linkId);
      setLinks(_.filter(links, (v) => v.id !== linkId));
    } catch(err) {
      setError(error);
    }
  }

  return (
    <div className="row">
      <div className="col-12">
        <h1>
          Homepage Links
          <NavLink to="/homepage/links/add" className="btn btn-success btn-sm ms-3">Add</NavLink>
        </h1>
            
        {error && <Alert message={error} />}

        {loading && <Spinner align="left" />}

        {!loading &&
          <>
            <table className="table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Category</th>
                  <th>Description</th>
                  <th>Background Color</th>
                  <th className="narrow">Icon</th>
                  <th>Order</th>
                  <th>Created At</th>
                  <th>Updated At</th>
                  { authContext.canDelete('homepage_links') && <th></th> }
                </tr>
              </thead>
              <tbody>
                {
                  links.length ? 
                    links.map(link => {
                      const createdAt = new Date(link.created_at);
                      const updatedAt = link.updated_at !== null ? new Date(link.updated_at) : null;
                      const categoryTitle = (_.find(categories, ['id', link.category_id]) || {}).title;
                      const backgroundColorStyle = {
                        border: '1px solid #000',
                        borderRadius: '3px',
                        width: '2.5rem',
                        height: '2.5rem',
                        backgroundColor: link.background_color,
                      };
                      const iconUrl = link.icon_file_name ? API.getMediaURL(link.icon_file_name) : false;
                      const iconBackgroundStyle = {
                        backgroundColor: link.background_color,
                      };

                      return (
                        <GoodTr key={link.id} to={`/homepage/links/${link.id}`}>
                          <td>
                            <NavLink to={`/homepage/links/${link.id}`} >{link.id}</NavLink>
                          </td>
                          <td>
                            {categoryTitle}
                          </td>
                          <td>{link.description}</td>
                          <td className="align-baseline">
                            {link.background_color}
                            <div style={backgroundColorStyle}></div>
                          </td>
                          <td className="narrow" style={iconBackgroundStyle}>
                            {iconUrl && <img src={iconUrl} className="mw-100" alt="icon" />}
                          </td>
                          <td>{link.order}</td>
                          <td>{createdAt.toLocaleDateString()} {createdAt.toLocaleTimeString()}</td>
                          <td>{updatedAt && updatedAt.toLocaleDateString()} {updatedAt && updatedAt.toLocaleTimeString()}</td>
                          { authContext.canDelete('homepage_links') && 
                            <td>
                              <ActionButton onClick={() => deleteLink(link.id)} label="Delete" className="btn-danger btn-sm" />
                            </td>
                          }
                        </GoodTr>
                      )
                    })
                  :
                    <tr>
                      <td colSpan="9" className="text-center">No links added.</td>
                    </tr>
                }
              </tbody>
            </table>
          </>
        }
      </div>
    </div>
  );
}
