import { useState, useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import Spinner from '../common/Spinner';
import API from '../API';
import Alert from '../common/Alert';
import GoodTr from '../common/GoodTr';
import { AuthContext } from '../AuthContext';
import ActionButton from '../common/ActionButton';

export default function CategoriesList() {
  const authContext = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    setLoading(true);

    async function fetchData() {
      try {
        setCategories(await API.getCategories());
      } catch(err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  async function deleteCategory(id) {
    if (!window.confirm(`Are you sure? All listings from "${categories.find(v => v.id === id).title}" will be removed.\n\nBefore removing please ensure no Ads are pointing to this ad.`)) {
      return false;
    }

    try {
      await API.deleteCategory(id);
      setCategories(categories.filter(v => v.id !== id));
    } catch(error) {
      setError(error.toString());
    }

    return true;
  }

  return (
    <div className="row">
      <div className="col-12">
        <h1>
          Categories
          { authContext.canAdd('categories') && <NavLink to="/categories/add" className="btn btn-success btn-sm ms-3">Add</NavLink> }
        </h1>
            
        {error && <Alert message={error} onHide={() => setError(false)} />}

        {loading && <Spinner align="left" />}

        {!loading &&
          <>
            <table className="table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Title</th>
                  <th></th>
                  <th>Created At</th>
                  <th>Updated At</th>
                  { authContext.canDelete('categories') && <th></th> }
                </tr>
              </thead>
              <tbody>
                {categories.map(category => {
                  const createdAt = new Date(category.created_at);
                  const updatedAt = category.updated_at !== null ? new Date(category.updated_at) : null;

                  return (
                    <GoodTr key={category.id} to={`/categories/${category.id}`}>
                      <td>
                        <NavLink to={`/categories/${category.id}/listings`}>{category.id}</NavLink>
                      </td>
                      <td>{category.title}</td>
                      <td>
                        <NavLink to={`/categories/${category.id}/listings`} className="btn btn-primary btn-sm">Listings</NavLink>
                      </td>
                      <td>{createdAt.toLocaleDateString()} {createdAt.toLocaleTimeString()}</td>
                      <td>{updatedAt && updatedAt.toLocaleDateString()} {updatedAt && updatedAt.toLocaleTimeString()}</td>
                      { authContext.canDelete('categories') && 
                        <td>
                          <ActionButton onClick={() => deleteCategory(category.id)} label="Delete" className="btn-danger btn-sm" />
                        </td>
                      }
                    </GoodTr>
                  )
                })}
              </tbody>
            </table>
          </>
        }
      </div>
    </div>
  );
}
