import { useHistory } from 'react-router-dom';
import API from '../API';
import Listing from './Listing';
import { useQuery } from '../Utils';

export default function ListingAdd() {
  const history = useHistory();
  const query = useQuery();

  async function submitHandler(data) {
    await API.createListing(data);
    history.push('/listings');
  }

  function getListing() {
    return {
      category_id: query.get('category_id'),
    }
  }

  return (
    <Listing
      mode="add" title="Add Listing" submitLabel="Add"
      getListingHandler={getListing}
      submitHandler={submitHandler}
    />
  );
}
