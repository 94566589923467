import { useHistory } from 'react-router-dom';
import API from '../API';
import Ad from './Ad';

export default function HomepageAdAdd() {
  const history = useHistory();

  async function submitHandler(data) {
    await API.createHomepageAd(data);
    history.push(`/homepage/ads?kiosk_id=${data.kiosk_id}`);
  }

  return (
    <Ad
      mode="add" title="Add Homepage Ad" submitLabel="Add"
      mediaRequired={true}
      submitHandler={submitHandler}
    />
  );
}
