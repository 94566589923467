import Alert from './Alert';
import Spinner from './Spinner';
import React, { useState } from "react";

const imagePreviewStyle = {
  maxHeight: '150px',
  maxWidth: '150px',
};

const videoPreviewStyle = {
  maxHeight: '200px',
  maxWidth: '100%',
};

const imageExtensions = ['jpg', 'jpeg', 'png', 'webp', 'gif', 'bmp', 'svg', ];
const videoExtensions = ['mpeg', 'mpg', 'mp4', 'webm', 'ogg'];

function getMediaType(url) {
  const extension = url.split('.').pop().toLowerCase();

  if (imageExtensions.includes(extension)) {
    return 'image';
  }

  if (videoExtensions.includes(extension)) {
    return 'video';
  }

  return null;
}

function Preview(props) {
  const { url } = props;
  const mediaType = getMediaType(url);

  switch(mediaType) {
    case 'image':
      return <img src={url} className="my-2" alt="" style={imagePreviewStyle} />;

    case 'video':
      return <video src={url} className="my-2" preload="none" controls style={videoPreviewStyle} />;

    default:
      return null;
  }
}

export default function MediaUploader (props) {
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState('');
  const fileRef =  React.createRef();

  function onChange(e) {
    let file = e.target.files[0];

    if (!file) {
      return;
    }

    setUploading(true);
    setError('');
    upload(file);
  }

  async function upload(file) {
    try {
      await props.uploader(file);
    } catch(e) {
      setError(e.toString());
    } finally {
      setUploading(false);
    }
  }

  return (
    <>
      <input type="file" className="form-control" onChange={onChange} ref={fileRef} required={props.required || false} />

      {error && <Alert message={error} />}

      {props.mediaUrl && !uploading && <Preview url={props.mediaUrl} />}
      {uploading && <Spinner className="mt-2" />}
    </>
  );
}
