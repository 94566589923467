import { useState, useEffect, useContext } from "react";
import { NavLink, useHistory } from "react-router-dom";
import Spinner from '../common/Spinner';
import API from '../API';
import Alert from '../common/Alert';
import ActionButton from '../common/ActionButton';
import GoodTr from '../common/GoodTr';
import _ from 'lodash';
import { AuthContext } from '../AuthContext';
import { useQuery } from '../Utils';

export default function HomepageAdsList() {
  const authContext = useContext(AuthContext);
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [kiosks, setKiosks] = useState([]);
  const [selectedKiosk, setSelectedKiosk] = useState('');
  const [categories, setCategories] = useState([]);
  const [allAds, setAllAds] = useState([]);
  const [ads, setAds] = useState([]);
  const query = useQuery();

  useEffect(() => {
    setLoading(true);

    async function fetchData() {
      try {
        setKiosks(await API.getKiosks());
        setCategories(await API.getCategories());
        setAllAds(await API.getHomepageAds());
      } catch(err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    setSelectedKiosk(query.get('kiosk_id') || '');
  }, [query]);

  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    setAds(allAds.filter(ad => !selectedKiosk || ad.kiosk_id == selectedKiosk));
  }, [selectedKiosk, allAds]);

  function showKioskAds(kioskId) {
    if (!kioskId) {
      query.delete('kiosk_id');
    } else {
      query.set('kiosk_id', kioskId);
    }
    history.push(`/homepage/ads?${query.toString()}`);
  }

  async function deleteAd(adId) {
    try {
      await API.deleteHomepageAd(adId);
      setAds(_.filter(ads, (v) => v.id !== adId));
    } catch(err) {
      setError(error);
    }
  }

  return (
    <div className="row">
      <div className="col-12">
        <h1>
          Homepage Ads
          <NavLink to="/homepage/ads/add" className="btn btn-success btn-sm ms-3">Add</NavLink>
        </h1>
            
        {error && <Alert message={error} />}

        {loading && <Spinner align="left" />}

        {!loading &&
          <>
            <table className="table caption-top">

              <caption>
                <select className="form-select" onChange={(e) => showKioskAds(e.target.value)} value={selectedKiosk}>
                  <option value="">All Kiosks</option>
                  {kiosks.map(kiosk => <option key={kiosk.id} value={kiosk.id}>{kiosk.title}</option>)}
                </select>
              </caption>

              <thead>
                <tr>
                  <th>ID</th>
                  <th>Kiosk</th>
                  <th>Category</th>
                  <th>Title</th>
                  <th>Media Type</th>
                  <th>Order</th>
                  <th>Created At</th>
                  <th>Updated At</th>
                  { authContext.canDelete('homepage_ads') && <th></th> }
                </tr>
              </thead>
              <tbody>
                {
                  ads.length ?
                    ads.map(ad => {
                      const createdAt = new Date(ad.created_at);
                      const updatedAt = ad.updated_at !== null ? new Date(ad.updated_at) : null;
                      const categoryTitle = _.find(categories, ['id', ad.category_id]).title;
                      const kioskTitle = ad.kiosk_id ? _.find(kiosks, ['id', ad.kiosk_id]).title : '-';

                      return (
                        <GoodTr key={ad.id} to={`/homepage/ads/${ad.id}`}>
                          <td>
                            <NavLink to={`/homepage/ads/${ad.id}`} >{ad.id}</NavLink>
                          </td>
                          <td>{kioskTitle}</td>
                          <td>{categoryTitle}</td>
                          <td>{ad.title}</td>
                          <td>{ad.type}</td>
                          <td>{ad.order}</td>
                          <td>{createdAt.toLocaleDateString()} {createdAt.toLocaleTimeString()}</td>
                          <td>{updatedAt && updatedAt.toLocaleDateString()} {updatedAt && updatedAt.toLocaleTimeString()}</td>
                          { authContext.canDelete('homepage_ads') && 
                            <td>
                              <ActionButton onClick={() => deleteAd(ad.id)} label="Delete" className="btn-danger btn-sm" />
                            </td>
                          }
                        </GoodTr>
                      )
                    })
                  :
                    <tr>
                      <td colSpan="9" className="text-center">No ads added.</td>
                    </tr>
                }
              </tbody>
            </table>
          </>
        }
      </div>
    </div>
  );
}
